<template>
  <el-container class="register-page">
    <el-row class="title" :gutter="20">
      <el-col :span="3" :offset="2">
        <img
          src="https://oss.sokogate.com/image/af82b848785b079390200737ab15cdff.png"
          alt=""
        />
      </el-col>
      <el-col :span="16" :offset="2" class="welcome">{{
        $t("login.welcomeRegister")
      }}</el-col>
      <el-col :span="2">
        <languag-switch />
      </el-col>
    </el-row>
    <el-row class="building" :gutter="20">
      <el-col :span="12" class="login">
        <el-card class="login-card">
          <div slot="header" class="clearfix">
            <span>{{ $t("login.welcomeRegister") }}</span>
          </div>
          <!-- 注册表格 -->
          <sui-form
            ref="form"
            :list="formList"
            :defaultdata="formData"
            @submit="submit"
            :submitWidth="100"
            submitTitle="register.register"
            :loading="formLoading"
          ></sui-form>
          <p class="bd-box">
            <el-button @click="login" type="text">
              {{ $t("register.existing") }}
            </el-button>
          </p>
          <div>
            <el-checkbox class="hb-checkbox" v-model="radioCode"></el-checkbox>
            <span class="agreement" @click="agreement">{{
              $t("resident.checkbox")
            }}</span>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12" class="loginbg">
        <img src="https://oss.sokogate.com/static/login_page_bg.png" alt="" />
      </el-col>
    </el-row>
    <el-row class="bottom-list">
      <el-col :span="24" :offset="10">
        <LinkDivider :list="linkList" />
      </el-col>
    </el-row>
    <el-row class="bottom">
      <el-col :span="24" :offset="10"
        >{{ $t("login.copyright") }}{{ $t("login.sokoGates")
        }}<version-no name="Vendor_Web"></version-no
      ></el-col>
    </el-row>

    <!-- 协议内容 -->
    <el-dialog
      :visible="myVisible"
      width="36%"
      @close="close"
      class="authentication"
    >
      <div class="userAgreement">
        <h2>{{ $t("agreement.agreement") }}</h2>
        <p>
          {{ $t("agreement.contnet1") }}
        </p>
        <p>
          {{ $t("agreement.content2") }}
        </p>
        <p>
          {{ $t("agreement.content3") }}
        </p>
        <h3>{{ $t("agreement.title1") }}</h3>
        <p>
          {{ $t("agreement.content1-1") }}
        </p>
        <p>
          {{ $t("agreement.content1-2") }}
        </p>
        <h4>{{ $t("agreement.title1-1-1") }}</h4>
        <p>
          {{ $t("agreement.content1-1-1") }}
        </p>
        <h4>{{ $t("agreement.title1-1-2") }}</h4>
        <p>
          {{ $t("agreement.contnet1-2-1") }}
        </p>
        <p>
          {{ $t("agreement.content1-2-2") }}
        </p>
        <h4>{{ $t("agreement.title1-1-3") }}</h4>
        <p>
          {{ $t("agreement.content1-3-1") }}
        </p>
        <p>
          {{ $t("agreement.content1-3-2") }}
        </p>
        <h3>{{ $t("agreement.title2") }}</h3>
        <ul>
          {{
            $t("agreement.title2-1")
          }}
        </ul>
        <li>
          {{ $t("agreement.content2-1-1") }}
        </li>
        <li>
          {{ $t("agreement.content2-1-2") }}
        </li>
        <li>
          {{ $t("agreement.content2-1-3") }}
        </li>
        <li>
          {{ $t("agreement.content2-1-4") }}
        </li>
        <li>
          {{ $t("agreement.content2-1-5") }}
        </li>
        <li>
          {{ $t("agreement.content2-1-6") }}
        </li>
        <h4>
          {{ $t("agreement.title2-2") }}
        </h4>
        <p>
          {{ $t("agreement.content2-2-1") }}
        </p>
        <h4>{{ $t("agreement.titleA") }}</h4>
        <p>
          {{
            $t("agreement.contentA-1")
          }}http://www.cites.org/eng/disc/what.shtml
        </p>
        <p>
          {{ $t("agreement.contentA-2") }}
        </p>
        <p>
          {{ $t("agreement.contentA-3") }}
        </p>
        <p>
          {{ $t("agreement.contentA-4") }}
        </p>
        <p>
          {{ $t("agreement.contentA-5") }}
        </p>
        <p>
          {{ $t("agreement.contentA-6") }}
        </p>
        <h4>{{ $t("agreement.titleB") }}</h4>
        <p>
          {{ $t("agreement.contentB-1") }}
        </p>
        <p>
          {{ $t("agreement.contentB-2") }}
        </p>
        <p>
          {{ $t("agreement.contentB-3") }}
        </p>
        <p>
          {{ $t("agreement.contentB-4") }}
        </p>
        <p>
          {{ $t("agreement.contentB-5") }}
        </p>
        <h4>{{ $t("agreement.titleC") }}</h4>
        <p>
          {{ $t("agreement.contentC-1") }}
        </p>
        <p>
          {{ $t("agreement.contentC-2") }}
        </p>
        <h4>{{ $t("agreement.titleD") }}</h4>
        <p>
          {{ $t("agreement.contentD-1") }}
        </p>
        <p>
          {{ $t("agreement.contentD-2") }}
        </p>
        <p>
          {{ $t("agreement.contentD-3") }}
        </p>
        <p>
          {{ $t("agreement.contentD-4") }}
        </p>
        <p>
          {{ $t("agreement.contentD-5") }}
        </p>
        <h4>{{ $t("agreement.titleE") }}</h4>
        <p>
          {{ $t("agreement.contentE-1") }}
        </p>
        <p>
          {{ $t("agreement.contentE-2") }}
        </p>
        <h4>{{ $t("agreement.titleF") }}</h4>
        <p>
          {{ $t("agreement.contentF-1") }}
        </p>
        <p>
          {{ $t("agreement.contentF-2") }}
        </p>

        <h4>{{ $t("agreement.titleG") }}</h4>
        <p>
          {{ $t("agreement.contentG-1") }}
        </p>
        <p>
          {{ $t("agreement.contentG-2") }}
        </p>
        <p>
          {{ $t("agreement.contentG-3") }}
        </p>
        <p>
          {{ $t("agreement.contentG-4") }}
        </p>
        <p>
          {{ $t("agreement.contentG-5") }}
        </p>

        <h4>{{ $t("agreement.titleH") }}</h4>
        <p>
          {{ $t("agreement.contentH-1") }}
        </p>
        <p>
          {{ $t("agreement.contentH-2") }}
        </p>
        <h4>{{ $t("agreement.titleI") }}</h4>
        <p>
          {{ $t("agreement.contentI-1") }}
        </p>
        <p>
          {{ $t("agreement.contentI-2") }}
        </p>
        <p>
          {{ $t("agreement.contentI-3") }}
        </p>
        <p>
          {{ $t("agreement.contentI-4") }}
        </p>
        <p>
          {{ $t("agreement.contentI-5") }}
        </p>

        <h4>{{ $t("agreement.titleJ") }}</h4>
        <p>
          {{ $t("agreement.contentJ-1") }}
        </p>
        <p>
          {{ $t("agreement.contentJ-2") }}
        </p>
        <p>
          {{ $t("agreement.contentJ-3") }}
        </p>
        <p>
          {{ $t("agreement.contentJ-4") }}
        </p>
        <p>
          {{ $t("agreement.contentJ-5") }}
        </p>
        <p>
          {{ $t("agreement.contentJ-6") }}
        </p>
        <p>
          {{ $t("agreement.contentJ-7") }}
        </p>
        <p>
          {{ $t("agreement.contentJ-8") }}
        </p>
        <p>
          {{ $t("agreement.contentJ-9") }}
        </p>

        <h4>{{ $t("agreement.titleK") }}</h4>
        <p>
          {{ $t("agreement.contentK-1") }}
        </p>
        <p>
          {{ $t("agreement.contentK-2") }}
        </p>
        <p>
          {{ $t("agreement.contentK-3") }}
        </p>
        <h4>{{ $t("agreement.titleL") }}</h4>
        <p>
          {{ $t("agreement.contentL-1") }}
        </p>
        <p>
          {{ $t("agreement.contentL-2") }}
        </p>
        <p>
          {{ $t("agreement.contentL-3") }}
        </p>
        <h4>{{ $t("agreement.titleM") }}</h4>
        <p>
          {{ $t("agreement.contentM-1") }}
        </p>
        <p>
          {{ $t("agreement.contentM-2") }}
        </p>
        <p>
          {{ $t("agreement.contentM-3") }}
        </p>
        <h4>{{ $t("agreement.titleN") }}</h4>
        <p>
          {{ $t("agreement.contentN-1") }}
        </p>
        <p>
          {{ $t("agreement.contentN-2") }}
        </p>
        <p>
          {{ $t("agreement.contentN-3") }}
        </p>
        <h4>{{ $t("agreement.titleO") }}</h4>
        <p>
          {{ $t("agreement.contentO-1") }}
        </p>
        <p>
          {{ $t("agreement.contentO-2") }}
        </p>
        <p>
          {{ $t("agreement.contentO-3") }}
        </p>
        <p>
          {{ $t("agreement.contentO-4") }}
        </p>
        <h4>{{ $t("agreement.titleP") }}</h4>
        <p>
          {{ $t("agreement.contentP-1") }}
        </p>
        <p>
          {{ $t("agreement.contentP-2") }}
        </p>
        <p>
          {{ $t("agreement.contentP-3") }}
        </p>
        <h4>{{ $t("agreement.titleQ") }}</h4>
        <p>
          {{ $t("agreement.contentQ-1") }}
        </p>
        <p>
          {{ $t("agreement.contentQ-2") }}
        </p>
        <p>
          {{ $t("agreement.contentQ-3") }}
        </p>

        <h4>{{ $t("agreement.titleR") }}</h4>
        <p>
          {{ $t("agreement.contentR-1") }}
        </p>
        <p>
          {{ $t("agreement.contentR-2") }}
        </p>
        <p>
          {{ $t("agreement.contentR-3") }}
        </p>
        <p>
          {{ $t("agreement.contentR-4") }}
        </p>
        <p>
          {{ $t("agreement.contentR-5") }}
        </p>
        <p>
          {{ $t("agreement.contentR-6") }}
        </p>
        <p>
          {{ $t("agreement.contentR-7") }}
        </p>
        <h4>{{ $t("agreement.titleS") }}</h4>
        <p>
          {{ $t("agreement.contentS-1") }}
        </p>
        <p>
          {{ $t("agreement.contentS-2") }}
        </p>
        <p>
          {{ $t("agreement.contentS-3") }}
        </p>
        <h4>{{ $t("agreement.titleT") }}</h4>
        <p>
          {{ $t("agreement.contentT-1") }}
        </p>
        <h5>
          {{ $t("agreement.contentT-2") }}
        </h5>

        <h3>{{ $t("agreement.title3") }}</h3>
        <p>
          {{ $t("agreement.content3-1") }}
        </p>

        <h3>{{ $t("agreement.title4") }}</h3>
        <p>
          {{ $t("agreement.content4-1") }}
        </p>
        <h3>{{ $t("agreement.title5") }}</h3>
        <p>
          {{ $t("agreement.content5-1") }}
        </p>
        <p>
          {{ $t("agreement.content5-2") }}
        </p>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import LanguagSwitch from "@/components/LanguagSwitch";
import LinkDivider from "@/components/LinkDivider";
import SuiForm from "@/components/s-ui/form";
import VersionNo from "@/components/VersionNo";
import { AddUserAndStore } from "@/utils/api";

export default {
  components: {
    LanguagSwitch,
    LinkDivider,
    VersionNo,
    SuiForm,
  },
  data() {
    return {
      // 供应商协议内容
      myVisible: false,
      //同意条款的勾选
      radioCode: false,
      // 表单内容
      formList: [
        //名称
        {
          type: "input",
          name: "name",
          label: "register.name",
          rules: [
            {
              required: true,
              message: "register.names",
              trigger: "blur",
            },
          ],
        },
        // 手机号
        {
          type: "mobile",
          name: "mobile",
          label: "register.mymobile",
          rules: [
            {
              required: true,
              message: "register.mobilereg",
              trigger: "blur",
            },
            { eval: "validateGlobalMobile", trigger: "blur" },
          ],
        },
        // 邮箱
        {
          type: "mail",
          name: "email",
          label: "login.email",
          rules: [
            {
              required: true,
              message: "login.emailreg",
              trigger: "blur",
            },
            {
              type: "email",
              message: "login.judge",
              trigger: "blur",
            },
          ],
        },
        // 验证码
        {
          type: "code",
          name: "verifyCode",
          label: "login.verificationCode",
          rules: [
            {
              required: true,
              message: "login.verificationCodeRequired",
              trigger: "blur",
            },
          ],
        },
        // 密码
        {
          type: "password",
          name: "password",
          label: "login.password",
          rules: [
            {
              required: true,
              message: "login.passwords",
              trigger: "blur",
            },
            { eval: "validatePass", trigger: "blur" },
          ],
        },
        // 确认密码
        {
          type: "password",
          name: "confirmpassword",
          label: "login.confirmpassword",
          rules: [
            {
              required: true,
              message: "login.passwordregss",
              trigger: "blur",
            },
            { eval: "validatePass2", trigger: "blur" },
          ],
        },
        // 国家
        {
          type: "country",
          name: "country",
          label: "register.country",
          rules: [
            {
              required: true,
              message: "register.country",
              trigger: "blur",
            },
          ],
        },
        // 成为供应商
        {
          type: "radio",
          name: "kind",
          rules: [{ required: true }],
          range: [
            {
              label: "shop.resident.businessman",
              value: 0,
            },
            {
              label: "shop.resident.logisticsProvider",
              value: 1,
            },
          ],
          setHidden: ["categoryId", 0, 0],
        },
        // 销售地
        {
          type: "radio",
          name: "SupplyPoint",
          label: "register.salesMethod",
          rules: [{ required: true }],
          range: [
            {
              label: "shop.resident.locally",
              value: 0,
            },
            {
              label: "shop.resident.globally",
              value: 1,
            },
          ],
          // 初始时隐藏子单选框
          setHidden: true,
        },
        // 店名
        {
          type: "input",
          name: "storeName",
          label: "shop.resident.storeName",
          rules: [
            {
              required: true,
              message: "shop.resident.storeNameReq",
              trigger: "blur",
            },
          ],
        },
        // 主要分类
        {
          type: "categoryId",
          name: "categoryId",
          label: "shop.resident.categoryIdList",
          rules: [
            {
              required: true,
              message: "shop.resident.categoryselect",
              trigger: "blur",
            },
          ],
        },
        // 公司地址
        {
          type: "input",
          name: "address",
          label: "shop.resident.address",
          rules: [
            {
              required: true,
              message: "shop.resident.addressReq",
              trigger: "blur",
            },
          ],
        },
        // 企业名称
        {
          type: "input",
          name: "enterpriseName",
          label: "shop.resident.enterpriseName",
          rules: [
            {
              required: true,
              message: "shop.resident.enterpriseNameReq",
              trigger: "blur",
            },
          ],
        },
        // 营业执照
        {
          type: "uploadimagenotoken",
          name: "businessLicenseImg",
          label: "shop.resident.businessLicenseImg",
          rules: [
            {
              required: true,
              message: "shop.resident.businessLicenseImgReq",
              trigger: "blur",
            },
          ],
        },
        // 身份证正面照
        // {
        //   type: "uploadimagenotoken",
        //   name: "identityCardFront",
        //   label: "shop.resident.identityCardFront",
        //   rules: [
        //     {
        //       required: true,
        //       message: "shop.resident.identityCardFrontReq",
        //       trigger: "blur",
        //     },
        //   ],
        // },
        // 身份证反面照
        // {
        //   type: "uploadimagenotoken",
        //   name: "identityCardBack",
        //   label: "shop.resident.identityCardBack",
        //   rules: [
        //     {
        //       required: true,
        //       message: "shop.resident.identityCardBackReq",
        //       trigger: "blur",
        //     },
        //   ],
        // },
        // 品牌标志
        // {
        //   type: "uploadimagenotoken",
        //   name: "brandLogo",
        //   label: "shop.resident.brandLogo",
        // },
        // 产品证书
        // {
        //   type: "uploadimagenotoken",
        //   name: "productCert",
        //   label: "shop.resident.productCert",
        // },
        // 商标注册证
        // {
        //   type: "uploadimagenotoken",
        //   name: "trademarkCert",
        //   label: "shop.resident.trademarkCert",
        // },
      ],
      // 表单的参数
      formData: {
        userType: 3,
        name: "",
        phoneCode: "86",
        verifyCode: "",
        country: "",
        mobile: "",
        email: "",
        password: "",
        kind: 0,
        SupplyPoint: 0,
        storeName: "",
        categoryId: "",
        address: "",
        // profile: "",
        enterpriseName: "",
        businessLicenseImg: "",
        // identityCardFront: "",
        // identityCardBack: "",
        // brandLogo: "",
        // productCert: "",
        // trademarkCert: "",
      },
      linkList: [
        {
          title: "dashboard",
        },
        {
          title: "orders",
        },
        {
          title: "commodity",
        },
        {
          title: "shop",
        },
        {
          title: "subscriptions",
        },
        {
          title: "accounts",
        },
        {
          title: "store",
        },
        {
          title: "settings",
        },
        {
          title: "evaluation",
        },
        {
          title: "management",
        },
        {
          title: "personal",
        },
        {
          title: "settings",
        },
        {
          title: "shop",
        },
        {
          title: "decoration",
        },
        {
          title: "picture",
        },
        {
          title: "space",
        },
      ],
      formLoading: false,
    };
  },
  methods: {
    // 协议弹框
    agreement() {
      this.myVisible = true;
    },
    // 关闭弹窗
    close() {
      this.myVisible = false;
    },
    // 去登录
    login() {
      this.$router.push("/v2/login");
    },
    // 注册的表单事件
    submit(data) {
      console.log("submit:", data);
      // 如果没勾选供应商协议 出现提示
      if (this.radioCode !== true) {
        this.$message({
          showClose: true,
          message: this.$t("resident.agreement"),
          type: "error",
        });
      }
      // 必须要勾选才能注册
      if (this.radioCode == true) {
        AddUserAndStore(data)
          .then((res) => {
            console.log("registger-res:", res);
            // 注册之后出现注册成功提示并跳转到登录页面
            this.formLoading = false;
            this.$message({
              showClose: false,
              message: this.$t("register.success"),
              type: "success",
            });
            this.$router.push("/v2/login");
          })
          .catch((err) => {
            console.log("registger-err:", err);
            this.formLoading = false;
            this.$message({
              showClose: false,
              message: `${err.data.message}`,
              type: "error",
            });
          });
      }
    },
  },
};
</script>

<style lang="scss">
.register-page {
  display: flex;
  flex-direction: column;
  min-width: 1500px;
  max-width: 1500px;
  margin: 0 auto;

  .title {
    width: 100%;
    margin: 40px 0 0 100px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .welcome {
      font-size: 25px;
    }
  }
  .building {
    background-color: #23262f;
    width: 100%;
    height: 581px;
    position: relative;
    margin-top: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    .login {
      .login-card {
        position: absolute;
        top: 40px;
        left: 120px;
        width: 487px;
        border-radius: 5px;
        z-index: 999;
        .clearfix {
          font-size: 30px;
        }
        .agreement {
          font-size: 12px;
        }
      }
    }
    .loginbg {
      position: absolute;
      left: 700px;
      top: 30px;
      width: 731px;
      height: 512px;
    }
  }
  .bottom-list {
    margin: 30px 0 0 30px;
    display: flex;
    .el-link--inner {
      font-size: 12px;
      color: gray;
    }
  }
  .bottom {
    margin: 10px 0 0 30px;
    font-size: 12px;
    color: gray;
  }

  .authentication {
    .el-dialog {
      height: 80vh;
      overflow-y: auto;
      word-break: normal;
      .userAgreement {
        h2 {
          text-align: center;
          margin-bottom: 20px;
        }
        h3 {
          margin-top: 20px;
        }
        h4 {
          margin-top: 20px;
        }
        p {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>